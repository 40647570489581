import React, { Fragment, useState, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'
import Slide from '@material-ui/core/Slide'
import { Cancel, SaveAlt, Save } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Papa from 'papaparse'
import Moment from 'moment'

import LoadGender from 'components/services/LoadGender'
import imgLoader from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  }
}

export default function NewApplicants(props) {
  const classes = useStyles()
  const theme = useTheme()

  const [openAlert, setOpenAlert] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState('')
  const [errorServer, setErrorServer] = useState(false)
  const [openBackdrop, setoOpenBackdrop] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [valueTabs, setValueTabs] = React.useState(0)
  const [disabledMasive, setDisabledMasive] = useState(true)
  const [rowsImport, setRowsImport] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)
  const [exitAlert, setExitAlert] = useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const [values, setValues] = useState({
    email: '',
    document_id: '',
    name: '',
    lastName: '',
    gender: '',
    genderData: '',
    birthdate: '',
    errorEmail: false,
    errorDocument_id: false,
    errorLastName: false,
    errorName: false,
  })

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorEmail: false,
      errorDocument_id: false,
      errorLastName: false,
      errorName: false,
    })
    setOpenAlert(false)
  }

  const handleChangeDNI = (event) => {
    setValues({
      ...values,
      document_id: event.target.value,
      errorDocument_id: false,
    })
    setOpenAlert(false)
  }

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  const submitForm = (e) => {
    e.preventDefault()
    setOpenAlert(false)
    setErrorServer(false)

    if (valueTabs === 0) {
      if (values.document_id.length === 0) {
        setValues({ ...values, errorDocument_id: true })
        setMensaje('El campo "Documento" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else if (values.name.length === 0) {
        setValues({ ...values, errorName: true })
        setMensaje('El campo "Nombre" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else if (values.lastName.length === 0) {
        setValues({ ...values, errorLastName: true })
        setMensaje('El campo "Apellidos" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else if (values.document_id.length === 0) {
        setValues({ ...values, errorDocument_id: true })
        setMensaje('El campo "Documento" es obligatorio.')
        setOpenAlert(true)
        setError('error')
      } else {
        let valueData = [
          {
            value: {
              documentId: values.document_id,
            },
          },
        ]

        const value = DataListApplicant(valueData)

        if (value.length > 0) {
          setoOpenBackdrop(true)

          const dataValue = {
            documentId: values.document_id,
            name: values.name,
            lastName: values.lastName,
            email: values.email !== '' ? values.email.toLowerCase().trim() : '',
            profileId: 6,
            accountId: localStorage.getItem('account_id'),
          }

          if (values.birthdate !== '') {
            dataValue.birthdate = values.birthdate
          }

          if (values.gender !== '') {
            dataValue.genderId = values.gender
          }

          axios
            .post(`${urlServices}users`, dataValue, {
              headers: {
                application: keyAplication,
                Authorization: 'Bearer ' + keyAuthorization,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                setoOpenBackdrop(false)

                setValues({
                  ...values,
                  email: '',
                  name: '',
                  lastName: '',
                  document_id: '',
                  gender: '',
                  genderData: '',
                  birthdate: '',
                })

                setOpenAlert(true)
                setMensaje('Registro guardado con éxito.')
                setError('success')

                props.callBackRefresh()
              }
            })
            .catch((e) => {
              //console.log(e)
              if (e.response.status === 401) {
                setoOpenBackdrop(false)
                setTimeout(() => {
                  localStorage.clear()
                  setReturnLogin(true)
                }, 200)
              } else {
                setoOpenBackdrop(false)

                setMensaje('Error de conexión, intente más tarde.')
                setOpenAlert(true)
                setError('error')
                setErrorServer(true)
              }
            })
        } else {
          setMensaje('La cédula ingresada ya se encuentra registrada.')
          setValues({ ...values, errorDocument_id: true })
          setOpenAlert(true)
          setError('error')
        }
      }
    } else if (valueTabs === 1) {
      const dataApplicants = rowsImport.length

      if (dataApplicants > 0) {
        setoOpenBackdrop(true)

        const insertRow = async (row) => {
          try {
            await axios.post(`${urlServices}users`, row, {
              headers: {
                application: keyAplication,
                Authorization: `Bearer ${keyAuthorization}`,
              },
            })
            return true // Inserción exitosa
          } catch (e) {
            if (e.response && e.response.status === 401) {
              setTimeout(() => {
                localStorage.clear()
                setReturnLogin(true)
              }, 200)
            } else {
              console.log(e)
            }
            return false // Falló la inserción
          }
        }

        const insertAllRows = async () => {
          const results = await Promise.all(rowsImport.map(insertRow))
          setoOpenBackdrop(false)

          if (results.every((result) => result)) {
            setOpenAlert(true)
            setMensaje('Datos registrados con éxito.')
            setRowsImport([])
            setValueTabs(0)
            setDisabledMasive(true)
            props.callBackRefresh()
          } else {
            setErrorServer(true)
            setOpenAlert(true)
          }
        }

        insertAllRows()
      } else {
        setMensaje(
          'Ya los candidatos que están en el listado, están registrados.'
        )
        setOpenAlert(true)
        setError('warning')
      }
    }
  }

  const DataListApplicant = (dataArray) => {
    // Verificar que props.rows.data está definido y es un array
    const existingDocumentIds =
      props.rows.data && Array.isArray(props.rows.data)
        ? new Set(props.rows.data.map((data1) => data1.documentId))
        : new Set() // Si no es válido, inicializa un conjunto vacío

    // Filtrar dataArray para incluir solo los elementos que no están en existingDocumentIds
    const newArray = dataArray
      .filter((data) => !existingDocumentIds.has(data.value.documentId))
      .map((data) => data.value) // Extraer solo el valor necesario

    return newArray
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const handleDateChange = (e) => {
    setValues({
      ...values,
      birthdate: e.target.value,
    })
  }

  const getResponseGender = (pro) => {
    if (pro !== null) {
      setValues({
        ...values,
        gender: pro.id,
        genderData: pro,
      })
    } else {
      setValues({
        ...values,
        gender: '',
        genderData: pro,
      })
    }
  }

  const handleChangeIndex = (index) => {
    setValueTabs(index)
  }

  const handleChangeTabs = (event, newValue) => {
    setValues({
      ...values,
      email: '',
      name: '',
      lastName: '',
      document_id: '',
      gender: '',
      genderData: '',
      birthdate: '',
      errorEmail: false,
      errorDocument_id: false,
      errorLastName: false,
      errorName: false,
    })
    setDisabled(false)
    setValueTabs(newValue)
  }

  const changeHandler = (event) => {
    const file = event.target.files[0]

    if (!file) return

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const out = []
        const empty = []

        const validarEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

        results.data.forEach((row) => {
          const tieneDatos = Object.values(row).some(
            (elemento) => elemento !== ''
          )

          if (!tieneDatos) return

          if (!row.cedula) empty.push({ cedula: true })
          if (!row.nombres) empty.push({ nombres: true })
          if (!row.apellidos) empty.push({ apellidos: true })

          if (row.correo && !validarEmail(row.correo)) {
            empty.push({ correo: true })
          }

          const value = {
            documentId: row.cedula?.trim(),
            name: row.nombres,
            lastName: row.apellidos,
            email: row.correo ? row.correo.toLowerCase().trim() : '',
            profileId: '6',
            admin: '0',
            accountId: localStorage.getItem('account_id'),
          }

          if (row.genero) {
            value.genderId = row.genero.toUpperCase() === 'MASCULINO' ? 1 : 2
          }

          if (row.fecha_nacimiento) {
            const formattedDate = Moment(row.fecha_nacimiento).format(
              'YYYY-MM-DD'
            )
            if (formattedDate !== 'Invalid date') {
              value.birthdate = formattedDate
            } else {
              empty.push({ fecha_nacimiento: true })
            }
          }

          out.push({ value })
        })

        // Manejo de errores
        if (empty.length > 0) {
          setExitAlert(true)
          setMensaje(
            'Las columnas CÉDULA, NOMBRES y APELLIDOS son obligatorios. Verifique el formato del CORREO y la FECHA NACIMIENTO.'
          )
          setOpenAlert(true)
          setError('error')
        } else if (out.length === 0) {
          setExitAlert(true)
          setMensaje(
            'El documento no puede estar vacío o faltan datos obligatorios.'
          )
          setOpenAlert(true)
          setError('error')
        } else {
          const value = DataListApplicant(out)

          if (value.length > 0) {
            setRowsImport(value)
            setDisabledMasive(false)
          } else {
            setMensaje('Los candidatos ya están registrados.')
            setOpenAlert(true)
            setError('warning')
          }
        }
      },
    })
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        // onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={12} sm={12}>
              <div className={classes.titleCardFilter}>Nuevo candidato(s)</div>
              <div className={classes.lineBottom}></div>
              <Alert severity="info" style={{ fontSize: 12 }}>
                {' '}
                Al registrar una cuenta con correo electrónico, se le enviará al
                correo una clave temporal para el inicio de sesión. (Revisar
                tanto la bandeja de entrada como la del spam) y si no se
                registra correo electrónico automáticamente se genera una clave
                aleatoria.
              </Alert>
            </Grid>

            <Grid item xs={12} md={12}>
              <AppBar position="static" color="default">
                <Tabs
                  value={valueTabs}
                  onChange={handleChangeTabs}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="action tabs example"
                >
                  <Tab
                    label="Carga manual"
                    {...a11yProps(0)}
                    style={{ color: '#000' }}
                  />
                  <Tab
                    label="Carga masiva"
                    {...a11yProps(1)}
                    style={{ color: '#000' }}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={valueTabs}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={valueTabs} index={0} dir={theme.direction}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      backgroundColor: '#f8f8f8',
                      borderRadius: 5,
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="name"
                        value={values.name}
                        onChange={handleChange('name')}
                        label="Nombres"
                        error={values.errorName}
                        margin="dense"
                        fullWidth
                        required
                        variant="outlined"
                        style={{ background: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        label="Apellidos"
                        error={values.errorLastName}
                        margin="dense"
                        fullWidth
                        required
                        variant="outlined"
                        style={{ background: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberFormat
                        customInput={TextField}
                        name="document_id"
                        value={values.document_id}
                        onChange={(e) => handleChangeDNI(e)}
                        //prefix={"$"}
                        // thousandSeparator="."
                        //  decimalSeparator=","
                        allowNegative={false}
                        inputProps={{
                          style: {
                            textAlign: 'center',
                          },
                        }}
                        label="Documento"
                        autoComplete="off"
                        margin="dense"
                        fullWidth
                        required
                        helperText="Cédula, RUC o pasaporte."
                        error={values.errorDocument_id}
                        variant="outlined"
                        style={{ background: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="off"
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        label="Correo electrónico"
                        error={values.errorEmail}
                        margin="dense"
                        fullWidth
                        //  required
                        variant="outlined"
                        style={{ background: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LoadGender
                        callback={getResponseGender}
                        value={values.genderData}
                        error=""
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="date"
                        label="Fecha de nacimiento"
                        type="date"
                        defaultValue={''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handleDateChange(e)}
                        margin="dense"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        style={{ background: 'white' }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12}>
                        <br></br>
                        <div className={classes.cardTitle}>
                          {" "}
                          <Apps className={classes.iconFilter} /> Privilegios
                          que tiene el perfil &nbsp;&nbsp;
                          <Button
                            className={classes.btnGreen}
                            onClick={() => openEnable()}
                          >
                            Privilegios
                          </Button>
                        </div>
                      </Grid>*/}
                    <Grid item xs={12} md={12} style={{ marginTop: '3%' }}>
                      {disabled && (
                        <Alert severity="error">
                          El documento del candidato ya está registrado.
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {errorServer && (
                        <Alert severity="error">
                          Error en la consulta al servidor
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <br></br>
                      <center>
                        <Button
                          type="submit"
                          className={classes.buttonSubmit}
                          onClick={submitForm}
                          disabled={disabled}
                        >
                          Guardar
                        </Button>
                      </center>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueTabs} index={1} dir={theme.direction}>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      backgroundColor: '#f8f8f8',
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{ display: openBackdrop ? 'none' : 'block' }}
                    >
                      <Alert severity="warning">
                        <AlertTitle>IMPORTANTE</AlertTitle>- La columna&nbsp;
                        <strong>Cédula</strong>&nbsp; es importante mantener
                        este formato ej: en formato de "TEXTO" para agregar al
                        inicio 0 si lo requiere.
                        <br></br>- La columna&nbsp;
                        <strong>Fecha de nacimiento</strong> no es obligatorio,
                        pero si desea agregar una fecha es importante mantener
                        este formato ej: <strong>2000-10-20</strong>{' '}
                        seleccionando formato de celdas "Fecha" en el excel, no
                        se permite formato 1900-01-00.
                        <br></br> - La columna <strong>Género</strong> no es
                        obligatorio, pero si desea agregar un género es
                        importante mantener este formato ej:{' '}
                        <strong>MASCULINO o FEMENINO</strong>. <br></br>- Al
                        finalizar con el archivo excel, deberá ir a{' '}
                        <strong>"ARCHIVO y GUARDAR COMO"</strong>, seleccionar
                        el formato del archivo tipo&nbsp;
                        <strong>
                          "(CSV CODIFICADO EN UTF-8 (DELIMITADO POR COMAS))"{' '}
                        </strong>
                        y <strong>"GUARDAR"</strong>.
                      </Alert>
                    </Grid>
                    <Fragment>
                      <br></br>
                      <br></br>
                      <Grid item xs={12} md={12}>
                        <center>
                          {exitAlert ? (
                            <Alert variant="filled" severity="error">
                              <b>
                                Cierre nuevamente la ventana para evitar
                                errores.
                              </b>
                            </Alert>
                          ) : null}
                        </center>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <center>
                          <div
                            style={{ display: openBackdrop ? 'none' : 'block' }}
                          >
                            <input
                              accept=".csv"
                              style={{ display: 'none' }}
                              onChange={changeHandler}
                              id="contained-button-file"
                              multiple
                              type="file"
                              name="filecsv"
                            />
                            <a
                              href={'/documents/csvcandidatos.csv'}
                              download="csvcandidatos.csv"
                            >
                              <Button
                                className={classes.buttonLeftSteps2}
                                startIcon={<SaveAlt />}
                              >
                                Descargar formato
                              </Button>
                            </a>
                            <label htmlFor="contained-button-file">
                              <Button
                                className={classes.buttonContactGreen}
                                component="span"
                              >
                                Subir documento
                              </Button>
                            </label>
                          </div>
                        </center>
                        {openBackdrop ? (
                          <center>
                            <img
                              src={imgLoader}
                              alt="loader"
                              style={{
                                width: 60,
                                objectFit: 'contain',
                                marginTop: 10,
                              }}
                            />
                            <br></br>
                            <b style={{ fontSize: 12 }}>
                              No cierre esta ventana hasta que termine el
                              proceso...
                            </b>
                          </center>
                        ) : (
                          <center>
                            <Button
                              className={classes.buttonSubmit2}
                              onClick={submitForm}
                              disabled={disabledMasive}
                              startIcon={<Save />}
                              style={{
                                marginTop: 30,
                              }}
                            >
                              Guardar
                            </Button>
                          </center>
                        )}
                      </Grid>
                    </Fragment>
                  </Grid>
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
          <Snackbar
            open={openAlert}
            autoHideDuration={20000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
          >
            <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
              <Alert
                onClose={handleClose}
                severity={
                  error === 'error'
                    ? 'error'
                    : error === 'warning'
                    ? 'warning'
                    : 'success'
                }
                elevation={6}
                variant="filled"
              >
                {mensaje}
              </Alert>
            </Slide>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
