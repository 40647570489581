import React, { Fragment, useEffect, useState, useContext } from 'react'
import { UrlServicesContext } from '../../components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'

export default function LoadJobsAccount(props) {
  const [results, setResults] = useState([])
  const [error, setError] = useState(false)
  const [isLoadedd, setIsLoadedd] = useState(false)
  const [returnLogin, setReturnLogin] = React.useState(false)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    axios
      .get(
        `${urlServices}jobs-tpm/account/${localStorage.getItem('account_id')}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      .then((response) => {
        let result = response.data.data.filter(
          (filtered) =>
            filtered.status === 1 &&
            (props.all || filtered.managePasswords === true)
        )

        setResults(result)
        setIsLoadedd(true)
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
          setError(true)
        } else {
          setIsLoadedd(true)
          setError(false)
        }
      })
  }, [urlServices, keyAplication])

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>CARGO DE LA CUENTA</b>
        </Alert>
      ) : (
        ''
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: '100%' }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={results}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue)
          }}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cargo"
              variant="standard"
              error={props.error}
              required
            />
          )}
        />
      )}
    </Fragment>
  )
}
