import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { Search, Layers } from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import axios from 'axios'
import MaterialTable from 'material-table'
import { Grid, LinearProgress, Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import format from 'date-fns/format'

import EnableResultsTestEQ from 'views/dialog/EnableResultsPsychometricsAdmin'
import EnableResultsPsychometricsAdminColor from 'views/dialog/EnableResultsPsychometricsAdminColor'
import EnableInformationTestUser from 'views/dialog/EnableInformationTestUser'
import EnableRestartTestUserStop from 'views/dialog/EnableRestartTestUserStop'
import EnableSelectResultsTest from 'views/dialog/EnableSelectResultsTest'
import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

export default function StatusJobsPsychometrics() {
  const classes = useStyles()

  const [rows, setRows] = useState([])
  const [loader, setLoader] = useState(true)
  const [error, setError] = useState(false)
  const [returnLogin, setReturnLogin] = useState(false)
  const [openResults, setOpenResults] = useState(false)
  const [openSelectResults, setOpenSelectResults] = useState(false)
  const [openResultsColor, setOpenResultsColor] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [openInformation, setOpenInformation] = useState(false)
  const [openRestart, setOpenRestart] = useState(false)

  const keyAuthorization = localStorage.getItem('Session')
  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const columns = [
    {
      title: 'Candidato',
      field: 'allnames',
      width: '30%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'Prueba',
      field: 'jobtesttpm.test.name',
      width: '35%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'Cargo',
      field: 'jobtesttpm.job.name',
      width: '35%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
      },
    },
    {
      title: 'Estado',
      render: (rowData) => {
        const statusMap = {
          1: { text: 'POR COMENZAR', color: 'grey' },
          2: { text: 'INICIADA', color: 'blue' },
          3: { text: 'FINALIZADA', color: 'green' },
          4: { text: 'PAUSADA', color: 'orange' },
          5: { text: 'INACTIVA', color: 'red' },
        }
        const { text, color } = statusMap[rowData.status]
        return (
          <Fragment>
            <b style={{ color }}>{text}</b>{' '}
            {rowData.status === 2 && <LinearProgress />}
          </Fragment>
        )
      },
      width: '5%',
      cellStyle: {
        textAlign: 'CENTER',
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 10,
      },
    },
    {
      title: 'Iniciada',
      render: (rowData) =>
        rowData.startDate
          ? format(new Date(rowData.startDate), 'dd-MM-yyyy kk:mm:ss')
          : '',
      width: '8%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
        textAlign: 'center',
        color: 'green',
        fontWeight: 'bold',
      },
    },
    {
      title: 'Finalizada',
      render: (rowData) =>
        rowData.endDate
          ? format(new Date(rowData.endDate), 'dd-MM-yyyy kk:mm:ss')
          : '',
      width: '8%',
      cellStyle: {
        border: '1px solid rgb(241, 241, 241)',
        fontSize: 12,
        textAlign: 'center',
        color: 'green',
        fontWeight: 'bold',
      },
    },
    {
      title: '---',
      render: (rowData) =>
        rowData.status === 3 ? (
          <>
            <Button
              size="small"
              style={{ width: 80, fontSize: 10 }}
              className={classes.buttonSubmit4}
              onClick={() => OpenResult(rowData)}
            >
              Resultados
            </Button>
            {rowData.jobtesttpm.testId === 21 &&
              rowData.jobtesttpm.test.testsuserstpm.length > 0 &&
              rowData.jobtesttpm.test.testsuserstpm[0].viewTest === 1 && (
                <Button
                  size="small"
                  style={{
                    width: 80,
                    fontSize: 10,
                    marginTop: 5,
                    padding: '3px 5px',
                  }}
                  className={classes.buttonContactGreen}
                  onClick={() => OpenSelectResult(rowData)}
                >
                  Ver prueba
                </Button>
              )}
          </>
        ) : rowData.status === 2 ? (
          <Button
            size="small"
            style={{ width: 80, fontSize: 10 }}
            className={classes.buttonSubmit5}
            onClick={() => OpenInformation(rowData)}
          >
            Información
          </Button>
        ) : rowData.status === 4 ? (
          <Button
            size="small"
            style={{ width: 80, fontSize: 10 }}
            className={classes.buttonSubmit5}
            onClick={() => OpenRestart(rowData)}
          >
            Reanudar
          </Button>
        ) : (
          <Button
            size="small"
            style={{ width: 80, fontSize: 10 }}
            className={classes.buttonSubmit4}
            disabled
          >
            Resultados
          </Button>
        ),
      width: '3%',
      cellStyle: {
        textAlign: 'CENTER',
        border: '1px solid rgb(241, 241, 241)',
      },
      sorting: false,
    },
  ]

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${urlServices}jobs-users-tpm/account/${localStorage.getItem(
          'account_id'
        )}`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )
      if (response.status === 200) {
        if (response.data.length > 0) {
          // Agregar el campo allnames
          const updatedData = response.data.map((item) => ({
            ...item,
            allnames: `${item.applicant.name} ${item.applicant.lastName}`,
          }))

          const sortedData = updatedData.sort(
            (a, b) => new Date(b.startDate) - new Date(a.startDate)
          )

          setRows(sortedData)
        }
      }
    } catch (e) {
      if (e.response.status === 404) {
        setRows([])
      } else if (e.response.status === 401) {
        localStorage.clear()
        setReturnLogin(true)
      } else {
        setError(true)
        console.error(e)
      }
    } finally {
      setLoader(false)
    }
  }, [keyAplication, urlServices, keyAuthorization])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchData()
  }, [fetchData])

  const RefreshTable = useCallback(() => {
    setLoader(true)
    fetchData()
  }, [fetchData])

  const OpenInformation = (data) => {
    let out = {
      id: data.id,
      testId: data.jobtesttpm.testId,
      testName: data.jobtesttpm.test.name,
      userId: data.applicantId,
      userNames: data.applicant.name + ' ' + data.applicant.lastName,
    }

    setIdRow(out)
    setOpenInformation(true)
  }

  const OpenResult = (data) => {
    const out = {
      jobId: data.jobtesttpm.jobId,
      testName: data.jobtesttpm.test.name,
      testId: data.jobtesttpm.testId,
      userId: data.applicant.id,
      userNames: `${data.applicant.name} ${data.applicant.lastName}`,
      userDocument: data.applicant.documentId,
      userGender: data.applicant.genderId || 1,
    }

    setIdRow(out)
    if (data.jobtesttpm.testId !== 12) {
      setOpenResults(true)
    } else {
      setOpenResultsColor(true)
    }
  }

  const OpenSelectResult = (data) => {
    setIdRow(data)
    setOpenSelectResults(true)
  }

  const OpenRestart = (data) => {
    setIdRow(data)
    setOpenRestart(true)
  }

  const handleCloseOpenInformation = () => {
    setOpenInformation(false)
  }

  const handleCloseOpenRestart = () => {
    setOpenRestart(false)
  }

  const handleCloseOpenResults = () => setOpenResults(false)
  const handleCloseOpenSelectResults = () => setOpenSelectResults(false)
  const handleCloseOpenResultsColor = () => setOpenResultsColor(false)

  if (returnLogin) {
    return <Redirect to="/" />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Listado de estado de
              pruebas
            </div>
          </CardHeader>
          <CardBody>
            {error && (
              <Alert severity="error">
                Error en la consulta con el servidor.
              </Alert>
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <center>
                    <img
                      src={img}
                      alt="loader"
                      style={{
                        width: 60,
                        margin: '100px 0',
                      }}
                    />
                  </center>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <MaterialTable
                  title=""
                  columns={columns}
                  style={{
                    boxShadow: 'unset',
                    border: '1px solid rgb(241, 241, 241)',
                  }}
                  data={rows}
                  localization={{
                    pagination: {
                      labelRowsSelect: 'Filas',
                      labelDisplayedRows: '{from}-{to} de {count}',
                      firstTooltip: 'Primera página',
                      previousTooltip: 'Previo',
                      nextTooltip: 'Siguiente',
                      lastTooltip: 'Última página',
                    },
                    toolbar: {
                      searchTooltip: 'Filtrar',
                      searchPlaceholder: 'Buscar',
                    },
                    header: {
                      actions: ' --- ',
                    },
                    body: {
                      emptyDataSourceMessage: 'No hay datos.',
                      filterRow: {
                        filterTooltip: 'Filtro',
                      },
                    },
                  }}
                  actions={[
                    {
                      icon: 'refresh',
                      tooltip: 'Refresh Data',
                      isFreeAction: true,
                      onClick: RefreshTable,
                    },
                  ]}
                  icons={tableIcons}
                  options={{
                    search: true,
                    padding: 'default',
                    filtering: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: '#F2F2F2',
                      padding: '1%',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    },
                    cellStyle: { border: '1px solid #DDDDDD' },
                  }}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openResults && (
        <EnableResultsTestEQ
          open={openResults}
          exit={handleCloseOpenResults}
          id={idRow}
        />
      )}
      {openSelectResults && (
        <EnableSelectResultsTest
          open={openSelectResults}
          exit={handleCloseOpenSelectResults}
          id={idRow}
        />
      )}
      {openInformation && (
        <EnableInformationTestUser
          open={openInformation}
          exit={handleCloseOpenInformation}
          id={idRow}
          callBackRefresh={RefreshTable}
          path="jobs-users-tpm"
        />
      )}
      {openRestart && (
        <EnableRestartTestUserStop
          open={openRestart}
          exit={handleCloseOpenRestart}
          id={idRow}
          callBackRefresh={RefreshTable}
          path="jobs-users-tpm"
        />
      )}

      {openResultsColor && (
        <EnableResultsPsychometricsAdminColor
          open={openResultsColor}
          exit={handleCloseOpenResultsColor}
          id={idRow}
        />
      )}
    </GridContainer>
  )
}
