import React, { Fragment, useState, useEffect, useContext } from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'
import { Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { Cancel, Person, Print } from '@material-ui/icons'
import axios from 'axios'
import { Alert } from '@material-ui/lab'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
} from '@material-ui/core'

import img from 'assets/img/loading.gif'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function EnableResultsJobsIndividual(props) {
  const classes = useStyles()

  const [errorServer, setErrorServer] = useState(false)
  const [loader, setLoader] = useState(true)
  const [dataValueJobs, setDataValueJob] = useState([])
  const [rows, setRows] = useState([])
  const [returnLogin, setReturnLogin] = React.useState(false)

  let dataTest = props.id
  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  useEffect(() => {
    const fetchData = async () => {
      const dataValue = {
        jobId: dataTest.jobId,
        userId: dataTest.userId,
      }

      try {
        const response = await axios.post(
          `${urlServices}reports/individual/factors`,
          dataValue,
          {
            headers: {
              application: keyAplication,
              Authorization: `Bearer ${keyAuthorization}`,
            },
          }
        )

        if (response.status === 200) {
          setDataValueJob(response.data)
          setRows(response.data.jobFactor)
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setTimeout(() => {
            localStorage.clear()
            setReturnLogin(true)
          }, 200)
        } else {
          console.error('Error fetching data:', error)
          setErrorServer(true)
        }
      } finally {
        setLoader(false)
      }
    }

    fetchData()
  }, [keyAplication, urlServices, keyAuthorization, dataTest])

  const handleCloseDialog = (pro) => (event) => {
    props.exit()
  }

  if (returnLogin) {
    return <Redirect to="/" />
  }

  let totalScore = 0

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Reporte individual por factores y competencias
                </div>
                <div className={classes.lineBottom}></div>
                <br></br>
              </Grid>
              {errorServer && (
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                </Grid>
              )}
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <center>
                    <div
                      style={{
                        margin: '100px 0',
                      }}
                    >
                      <img
                        src={img}
                        alt="loader"
                        style={{
                          width: 60,
                        }}
                      />
                      <br></br>
                      <b style={{ fontSize: 10 }}>Cargando resultados</b>
                    </div>
                  </center>
                </Grid>
              ) : (
                <Fragment>
                  <Grid item xs={12} sm={8}>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                      <Person className={classes.iconTheme} />{' '}
                      {dataValueJobs.user.name +
                        ' ' +
                        dataValueJobs.user.lastName}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.titleFilterAction}>
                      Identificación: &nbsp;&nbsp;
                      {dataValueJobs.user.documentId}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleFilterAction}>
                      Cargo: &nbsp;&nbsp;{dataValueJobs.jobTpm.name}
                    </div>
                  </Grid>
                  {localStorage.getItem('profile_id') !== '6' ? (
                    <Grid item xs={12} sm={12}>
                      <div style={{ float: 'right', marginTop: 5 }}>
                        <Button
                          type="submit"
                          className={classes.buttonSubmit1}
                          href={
                            '/printresultsindividualjobs?jobId=' +
                            dataTest.jobId +
                            '&userId=' +
                            dataTest.userId
                          }
                          startIcon={<Print style={{ fontSize: 14 }} />}
                          component="a"
                          target="_blank"
                        >
                          Imprimir
                        </Button>
                      </div>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12} sm={12}>
                    <TableContainer>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                      >
                        <TableHead style={{ backgroundColor: '#F2F2F2' }}>
                          <TableRow>
                            <TableCell>COMPETENCIA</TableCell>
                            <TableCell>TEST</TableCell>
                            <TableCell>FACTOR EN TEST</TableCell>
                            <TableCell>CALIFICACIÓN</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => {
                            totalScore +=
                              row.testsResultsTpm === null
                                ? parseFloat(0)
                                : parseFloat(row.testsResultsTpm.score)
                            return (
                              <TableRow>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.test.code}</TableCell>
                                <TableCell>{row.factor.name}</TableCell>
                                <TableCell align="center">
                                  {row.testsResultsTpm === null
                                    ? ''
                                    : parseFloat(
                                        row.testsResultsTpm.score
                                      ).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size={'small'}
                          aria-label="enhanced table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell
                                align="right"
                                style={{
                                  background: 'cornflowerblue',
                                  color: 'black',
                                  fontWeight: 'bold',
                                }}
                              >
                                Calificación obtenida
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: 'black',
                                  color: 'white',
                                  fontWeight: 'bold',
                                }}
                              >
                                {parseFloat(totalScore).toFixed(2)}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  background: 'cornflowerblue',
                                  color: 'black',
                                  fontWeight: 'bold',
                                }}
                              >
                                Sobre{' '}
                                {parseFloat(
                                  dataValueJobs.jobTpm.totalScore
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
